@tailwind base;
@tailwind components;
@tailwind utilities;

button {
    transition: transform 200ms ease-in-out;
}

button:active {
    transform: scale(0.95);
}

.my-heading-1 {
    @apply text-[1.333rem] font-bold text-center mb-4; /* 글자 크기 16pt, 굵은 글씨, 아래 여백 추가 */
}

.my-heading-2 {
    @apply text-[1rem] font-bold mb-4; /* 글자 크기 12pt, 굵은 글씨, 아래 여백 추가 */
}

.my-heading-3 {
    @apply text-[0.833rem] font-bold mb-4; /* 글자 크기 10pt, 굵은 글씨, 아래 여백 추가 */
}

.my-heading-4 {
    @apply text-[0.75rem] font-bold mb-4; /* 글자 크기 9pt, 굵은 글씨, 아래 여백 추가 */
}

.my-heading-5 {
    @apply text-[0.667rem] font-bold mb-4; /* 글자 크기 8pt, 굵은 글씨, 아래 여백 추가 */
}

.my-heading-6 {
    @apply text-[0.5rem] font-bold mb-4; /* 글자 크기 6pt, 굵은 글씨, 아래 여백 추가 */
}

.my-normal {
    @apply text-[0.70875rem] text-black my-2; /* 글자 크기 8.5pt, 검은색 텍스트, 기본 여백 */
}

.my-toc {
    @apply text-[0.70875rem] text-black my-0; /* 글자 크기 8.5pt, 검은색 텍스트, 여백 없음 */
}

.loading-text3 {
    perspective: 1000px;
}

.loading-text3 span {
    transform-origin: 50% 50% -25px;
    transform-style: preserve-3d;
    animation: loading-text3 1.6s infinite;
}

.loading-text3 span:nth-child(2) {
    animation-delay: 0.05s;
}
.loading-text3 span:nth-child(3) {
    animation-delay: 0.1s;
}
.loading-text3 span:nth-child(4) {
    animation-delay: 0.15s;
}
.loading-text3 span:nth-child(5) {
    animation-delay: 0.2s;
}
.loading-text3 span:nth-child(6) {
    animation-delay: 0.25s;
}
.loading-text3 span:nth-child(7) {
    animation-delay: 0.3s;
}
.loading-text3 span:nth-child(8) {
    animation-delay: 0.35s;
}
.loading-text3 span:nth-child(9) {
    animation-delay: 0.4s;
}
.loading-text3 span:nth-child(10) {
    animation-delay: 0.45s;
}
.loading-text3 span:nth-child(11) {
    animation-delay: 0.5s;
}
.loading-text3 span:nth-child(12) {
    animation-delay: 0.55s;
}
.loading-text3 span:nth-child(13) {
    animation-delay: 0.6s;
}

@keyframes loading-text3 {
    0% {
        transform: rotateX(-360deg);
    }
    70% {
        transform: rotateX(0);
    }
}

.loading-text2 span {
    animation: loading-text2 0.7s infinite alternate;
}

.loading-text2 span:nth-child(2) {
    animation-delay: 0.05s;
}
.loading-text2 span:nth-child(3) {
    animation-delay: 0.1s;
}
.loading-text2 span:nth-child(4) {
    animation-delay: 0.15s;
}
.loading-text2 span:nth-child(5) {
    animation-delay: 0.2s;
}
.loading-text2 span:nth-child(6) {
    animation-delay: 0.25s;
}
.loading-text2 span:nth-child(7) {
    animation-delay: 0.3s;
}
.loading-text2 span:nth-child(8) {
    animation-delay: 0.35s;
}
.loading-text2 span:nth-child(9) {
    animation-delay: 0.4s;
}
.loading-text2 span:nth-child(10) {
    animation-delay: 0.45s;
}
.loading-text2 span:nth-child(11) {
    animation-delay: 0.5s;
}
.loading-text2 span:nth-child(12) {
    animation-delay: 0.55s;
}
.loading-text2 span:nth-child(13) {
    animation-delay: 0.6s;
}

@keyframes loading-text2 {
    0% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

/* code #1 */
.loading-text1 span {
    animation: loading-text 0.8s infinite alternate;
}

/* 각 nth-child에 대해 animation-delay 설정 */
.loading-text1 span:nth-child(1) {
    animation-delay: 0s;
}
.loading-text1 span:nth-child(2) {
    animation-delay: 0.05s;
}
.loading-text1 span:nth-child(3) {
    animation-delay: 0.1s;
}
.loading-text1 span:nth-child(4) {
    animation-delay: 0.15s;
}
.loading-text1 span:nth-child(5) {
    animation-delay: 0.2s;
}
.loading-text1 span:nth-child(6) {
    animation-delay: 0.25s;
}
.loading-text1 span:nth-child(7) {
    animation-delay: 0.3s;
}
.loading-text1 span:nth-child(8) {
    animation-delay: 0.35s;
}
.loading-text1 span:nth-child(9) {
    animation-delay: 0.4s;
}
.loading-text1 span:nth-child(10) {
    animation-delay: 0.45s;
}
.loading-text1 span:nth-child(11) {
    animation-delay: 0.5s;
}
.loading-text1 span:nth-child(12) {
    animation-delay: 0.55s;
}
.loading-text1 span:nth-child(13) {
    animation-delay: 0.6s;
}

@keyframes loading-text {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* HTML: <div class="loader"></div> */
.loader-small {
    --d: 9px; /* Adjusted to half the size */
    width: 1.5px; /* Adjusted to half the size */
    height: 1.5px; /* Adjusted to half the size */
    border-radius: 50%;
    color: #25b09b;
    box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
        calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 0.5px,
        calc(0 * var(--d)) calc(1 * var(--d)) 0 1px,
        calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 1.5px,
        calc(-1 * var(--d)) calc(0 * var(--d)) 0 2px,
        calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 2.5px,
        calc(0 * var(--d)) calc(-1 * var(--d)) 0 3px;
    animation: l27 1s infinite steps(8);
}

.loader {
    --d: 22px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    color: #25b09b;
    box-shadow: calc(1 * var(--d)) calc(0 * var(--d)) 0 0,
        calc(0.707 * var(--d)) calc(0.707 * var(--d)) 0 1px,
        calc(0 * var(--d)) calc(1 * var(--d)) 0 2px,
        calc(-0.707 * var(--d)) calc(0.707 * var(--d)) 0 3px,
        calc(-1 * var(--d)) calc(0 * var(--d)) 0 4px,
        calc(-0.707 * var(--d)) calc(-0.707 * var(--d)) 0 5px,
        calc(0 * var(--d)) calc(-1 * var(--d)) 0 6px;
    animation: l27 1s infinite steps(8);
}
@keyframes l27 {
    100% {
        transform: rotate(1turn);
    }
}

.printing {
    position: relative;
    width: 120px;
    height: 55px;
    background-repeat: no-repeat;
    background-image: radial-gradient(circle 2.5px, #ff3d00 100%, transparent 0),
        linear-gradient(#525252 90px, transparent 0),
        linear-gradient(#ececec 120px, transparent 0),
        linear-gradient(to right, #eee 10%, #333 10%, #333 90%, #eee 90%);

    background-size: 5px 5px, 90px 10px, 120px 45px, 100px 15px;
    background-position: 110px 15px, center bottom, center bottom, center 0;
}
.printing:before {
    content: "";
    width: 70px;
    background-color: #fff;
    box-shadow: 0 0 10px #0003;
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
    bottom: calc(100% - 10px);
    animation: printerPaper 4s ease-in infinite;
}
.printing:after {
    content: "";
    width: 70px;
    height: 80px;
    background-color: #fff;
    background-image: linear-gradient(to bottom, #fff 50%, #ff3d00 51%),
        linear-gradient(to bottom, #bbb 50%, #0000 51%);
    background-size: 60px 20px, 60px 10px;
    background-repeat: no-repeat, repeat-y;
    background-position: center 55px, center 0;
    position: absolute;
    left: 50%;
    transform: translatex(-50%) rotate(180deg);
    box-shadow: 0 10px #fff inset;
    top: calc(100% - 8px);
    animation: PrintedPaper 4s ease-in infinite;
}

@keyframes printerPaper {
    0%,
    25% {
        height: 50px;
    }
    75%,
    100% {
        height: 0;
    }
}

@keyframes PrintedPaper {
    0%,
    30% {
        height: 0px;
        top: calc(100% - 8px);
    }

    80% {
        height: 80px;
        top: calc(100% - 8px);
        opacity: 1;
    }
    100% {
        height: 80px;
        top: calc(100% + 10px);
        opacity: 0;
    }
}

@media (max-width: 850px) {
    .hide-on-small {
        display: none;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    14.28% {
        opacity: 1;
    }
    28.56% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.image-container {
    position: relative;
    width: 200px; /* 원하는 이미지 크기 */
    height: 200px; /* 원하는 이미지 크기 */
}

.image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fade 3.5s infinite;
}

.image-container img:nth-child(1) {
    animation-delay: 0s;
}

.image-container img:nth-child(2) {
    animation-delay: 0.5s;
}

.image-container img:nth-child(3) {
    animation-delay: 1s;
}

.image-container img:nth-child(4) {
    animation-delay: 1.5s;
}

.image-container img:nth-child(5) {
    animation-delay: 2s;
}

.image-container img:nth-child(6) {
    animation-delay: 2.5s;
}

.image-container img:nth-child(7) {
    animation-delay: 3s;
}

.multiline-truncate {
    display: -webkit-box;
    -webkit-line-clamp: 4; /* 보여줄 줄 수 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button {
    @apply flex p-[10px_24px] justify-center items-center gap-[10px] rounded-[22px] font-sans text-[16px] font-bold leading-normal;
}

.button-text {
    @apply flex p-[10px_0px] justify-center items-center gap-[10px] text-[#131313] font-sans text-[16px] font-bold leading-normal;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer svg path {
    transition: fill 0.1s ease; /* 부드러운 전환 효과 */
}

.cursor-pointer svg:hover path {
    fill: gray; /* 회색으로 변경 */
}

.custom-input {
    outline: none; /* 포커스 시 외곽선 제거 */
    color: black; /* 텍스트 색상 검정으로 설정 */
    font-weight: 600;
}

.custom-input::focus {
    background-color: white;
}

.custom-input::placeholder {
    font-weight: 400; /* 플레이스홀더 텍스트는 굵게 처리하지 않음 */
    color: #ababab;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
